import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import { PostDate } from "../../common/general-styles.component"
import { QuinaryHeading } from "../../common/heading.component"
import { Image } from "../../common/image.component"
import {
  BaseLink,
  NeutralBoldRawLink,
} from "../../../ui/components/link/link.component"
import Tag from "../../common/tag.component"
import { BlogPostPreview } from "./blog.interface"
import { IGatsbyImageData } from "gatsby-plugin-image"
import useFallbackImage from "../../../hooks/use-fallback-image.hook"
import Loader from "../../common/loader.component"

const ResultsWrapper = styled.div`
  display: grid;
  grid-gap: 50px 15px;
  align-items: stretch;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    grid-template-columns: repeat(3, minmax(260px, 1fr));
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    grid-gap: 50px 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    grid-gap: 70px 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktopMedium}px) {
    grid-gap: 90px 40px;
  }
`

const SinglePostImage = styled(Image)`
  height: 0;
  width: 100%;
  padding-bottom: 80%;

  img {
    object-fit: cover;
  }
`

const SinglePostText = styled.div`
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.background};

  ${QuinaryHeading} {
    margin-top: 0;
    min-height: 3em;
  }

  ${PostDate} {
    margin-top: 0;
  }

  p {
    flex: 1;
  }
`

const SinglePost = styled.article`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.size.borderRadius};
`
const FullLoader = styled(Loader)`
  width: 100%;
`
const NoResults = styled(Loader)`
  color: ${({ theme }) => theme.color.text};
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-bottom: 0;
  }
`

interface Posts {
  posts: BlogPostPreview[]
  pagination?: React.ReactNode
  fallbackImage?: IGatsbyImageData
  pending?: boolean
}

const BlogResults: AppFunctionComponent<Posts> = (props) => {
  const fallbackImage = useFallbackImage()
  return <BlogResultsInternal {...props} fallbackImage={fallbackImage} />
}

const BlogResultsInternal: AppFunctionComponent<Posts> = ({
  posts,
  pagination,
  fallbackImage,
  pending,
}) => (
  <div>
    {pending && <FullLoader />}
    <ResultsWrapper>
      {posts.length && !pending
        ? posts
            .sort(
              (a, b) =>
                new Date(b.postOriginalPublishDate).getTime() -
                new Date(a.postOriginalPublishDate).getTime()
            )
            .map((post) => {
              const {
                postTitle,
                postSlug,
                postExcerpt: { postExcerpt },
                postTags,
                postFeaturedImage: { gatsbyImageData },
                postOriginalPublishDate,
                postOriginalPublishDateFormatted,
              } = post
              return (
                <SinglePost key={post.postOriginalPublishDate}>
                  <BaseLink
                    to={`/community/blog/${postSlug}`}
                    aria-labelledby={`post-${postSlug}`}
                  >
                    <SinglePostImage image={gatsbyImageData || fallbackImage} />
                  </BaseLink>
                  <SinglePostText>
                    <QuinaryHeading renderAs={"h3"}>
                      <NeutralBoldRawLink
                        to={`/community/blog/${postSlug}`}
                        id={`post-${postSlug}`}
                      >
                        {postTitle}
                      </NeutralBoldRawLink>
                    </QuinaryHeading>
                    <PostDate dateTime={postOriginalPublishDate}>
                      {postOriginalPublishDateFormatted}
                    </PostDate>
                    <p>{postExcerpt}</p>
                    <Tags>
                      {postTags &&
                        post.postTags.map((tag, i) => (
                          <Tag raw key={i}>
                            {tag.text}
                          </Tag>
                        ))}
                    </Tags>
                  </SinglePostText>
                </SinglePost>
              )
            })
        : !pending && <NoResults>No results found.</NoResults>}
    </ResultsWrapper>
    {!pending && pagination}
  </div>
)

export default BlogResults
