import { AppFunctionComponent, TextBuilder } from "../types"
import { graphql } from "gatsby"
import React from "react"
import BlogPostArticle from "../components/community/blog/blog-post-article.component"
import { BlogPost } from "../components/community/blog/blog.interface"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import { ContentfulSection, PageQueryResult } from "../types"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Menu } from "../navigation/types/menu.interface"

const BlogPostPage: AppFunctionComponent<
  PageQueryResult<{
    post: BlogPost
    fallbackImage?: IGatsbyImageData
    defaultNewsletterContent: ContentfulSection
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }>
> = ({
  data: {
    post,
    defaultNewsletterContent,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  const {
    postTitle,
    postExcerpt: { postExcerpt },
    postMetaImage,
    postCannonicalUrl,
    meta,
  } = post
  const defaultMeta = {
    title: postTitle,
    facebookTitle: postTitle,
    twitterTitle: postTitle,
    description: postExcerpt,
    facebookDescription: postExcerpt,
    twitterDescription: postExcerpt,
    facebookImage: postMetaImage,
  }
  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      withSmoothScroll
    >
      <SEO
        {...(meta || defaultMeta)}
        post={post}
        canonical={postCannonicalUrl}
      />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <BlogPostArticle
        defaultNewsletterContent={defaultNewsletterContent}
        post={post}
      />
    </Layout>
  )
}

export default BlogPostPage

export const query = graphql`
  query BlogPostPageQuery($id: String!, $language: String!, $locale: String!) {
    post: contentfulBlogPost(id: { eq: $id }) {
      meta: metaTags {
        ...PageMeta
      }
      contentful_id
      postSlug
      postTitle
      postOriginalPublishDate
      postOriginalPublishDateFormatted: postOriginalPublishDate(
        formatString: "MMM D, YYYY"
      )
      postTags {
        text
      }
      postContent {
        raw
        references {
          ...Video
          ...Footnote
          ...Asset
          ...ResponsiveImage
        }
      }
      postExcerpt {
        postExcerpt
      }
      postFeaturedImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 95
          width: 600
        )
        title
        description
      }
      postDiscourseCategory
      postCannonicalUrl
      postDiscourseId
      postGuestAuthor {
        personName
        personDescription
        personImage {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 95
            height: 100
            width: 100
          )
        }
      }
      postMetaImage: postFeaturedImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          width: 1200
        )
      }
    }
    defaultNewsletterContent: contentfulSection(
      contentfulid: { eq: "default/newsletter" }
      node_locale: { eq: $locale }
    ) {
      title
      text {
        raw
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
