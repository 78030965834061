import React from "react"
import styled from "styled-components"
import commentIcon from "../../../images/icons/comment.svg"
import { AppFunctionComponent } from "../../../types"
import Author from "../../common/author.component"
import CommunityCommentsCount from "../../common/community-comments-count/community-comments-count.component"
import { useCommentsData } from "../../common/community-comments-count/use-comments"
import Footnotes from "../../common/footnotes.component"
import {
  footnotesAnchors,
  gridSpacing,
  PostDate,
  postDateStyles,
  quoteStyles,
} from "../../common/general-styles.component"
import {
  PrimaryHeading,
  SecondaryHeading,
} from "../../common/heading.component"
import { NeutralTextLink } from "../../../ui/components/link/link.component"
import StandardSection, {
  SectionArticle,
  SectionImage,
  SectionLayout,
} from "../../common/sections/section.component"
import Tag from "../../common/tag.component"
import BlogCommments from "./blog-comments.component"
import { renderBlogPost } from "./blog-post-rich-text-options"
import { BlogPost } from "./blog.interface"
import Newsletter from "../../newsletter/newsletter.component"
import { IGatsbyImageData } from "gatsby-plugin-image"
import useFallbackImage from "../../../hooks/use-fallback-image.hook"
import BackLink from "../../../navigation/components/back-link/back-link.component"
import { Path } from "../../../navigation/constants/routes"
import BlogResults from "./blog-results"
import Section from "../../common/sections/section.component"
import { graphql, useStaticQuery } from "gatsby"
import SocialMediaShare from "../../media/social-media-share/social-media-share.component"
import { ContentfulSection } from "../../../types"

const PostWrapper = styled.article`
  ${gridSpacing};
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: var(--grid-spacing);
`

const HeaderArticle = styled(SectionArticle)`
  ${PrimaryHeading} {
    margin-bottom: 0;
    word-break: break-word;
  }
`

const ArticleTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin-top: 0;
  }
`

const CommunityLink = styled(NeutralTextLink)`
  ${postDateStyles};
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;

  img {
    margin-right: 10px;
    height: ${14 / 16}rem;
    margin-top: -2px;
  }
`

const ForumInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;

  ${PostDate} {
    margin: 2px 30px 10px 0;
    line-height: ${14 / 16}rem;
  }

  ${CommunityLink} {
    margin: 10px 0;
    line-height: ${14 / 16}rem;
  }

  & + ${Author} {
    margin-top: 50px;
  }
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    flex-direction: column;
  }

  ${NeutralTextLink} {
    display: block;
    line-height: 1;
    & + ${NeutralTextLink} {
      margin-left: 20px;

      @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
`

const PostHeader = styled.header`
  ${SectionImage} {
    margin-bottom: 0;
    max-height: 460px;
  }
`

const PostArticle = styled(SectionArticle)`
  margin: 0 auto;
  max-width: 640px;

  blockquote:not(+ article) {
    ${quoteStyles};
    margin: 30px 0;
    @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
      margin: 60px -40px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
      margin: 90px -80px;
    }

    p {
      font-size: ${23 / 16}rem;
      line-height: ${40 / 23}em;
    }
    p,
    cite {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ul {
    display: block;
    padding-left: 1.8em;
  }
  li {
    margin-bottom: 1em;
  }
  ${StandardSection} {
    width: 100%;
    --padding: 30px;
    padding: var(--padding) 0;

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
      --padding: 60px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
      --padding: 90px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + ${StandardSection} {
      padding-top: 0;
    }
  }
  ${footnotesAnchors};
  ${StandardSection} {
    padding: calc(var(--padding) / 1.5) 0;

    + * {
      margin-top: 0;
      padding-top: 0;
    }
  }
`

const PostComments = styled.div`
  margin: 0;
`

const PostContent = styled(StandardSection)`
  display: grid;
  grid-row-gap: 30px;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas: "Social" "Article" ".";

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    grid-template-columns: 2fr 8fr;
    grid-template-areas: "Social Article";
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-areas: "Social Article .";
  }

  ${PostArticle} {
    padding: 0;
  }
`

const Back = styled(BackLink)`
  margin-bottom: 30px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-top: 0;
  }
`
const StoriesWrapper = styled(Section)`
  grid-row-gap: 30px;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
`

interface Props {
  post: BlogPost
  fallbackImage?: IGatsbyImageData
  defaultNewsletterContent: ContentfulSection
}

const BlogPostArticle: AppFunctionComponent<Props> = (props) => {
  const fallbackImage = useFallbackImage()

  return <BlogPostArticleInternal {...props} fallbackImage={fallbackImage} />
}

const BlogPostArticleInternal: AppFunctionComponent<Props> = ({
  className = "",
  post: {
    contentful_id,
    postTitle,
    postSlug,
    postContent,
    postTags,
    postFeaturedImage: { gatsbyImageData },
    postOriginalPublishDate,
    postOriginalPublishDateFormatted,
    postDiscourseId,
    postDiscourseCategory,
  },
  fallbackImage,
  defaultNewsletterContent,
}) => {
  const { posts } = useStaticQuery(query)

  const { footnotes, blogPostContent } = renderBlogPost(postContent)
  const lang = "en-US"

  const { count, comments } = useCommentsData(postDiscourseId || 0, true)

  return (
    <PostWrapper className={className}>
      <PostHeader>
        <StandardSection layout={SectionLayout.ImageText}>
          <Back to={Path.Blog}>Back</Back>
          <SectionImage
            disableShadow
            image={gatsbyImageData || fallbackImage}
            loading="eager"
          />
          <HeaderArticle>
            <ArticleTags>
              {postTags &&
                postTags.map((tag, index) => (
                  <Tag raw key={index}>
                    {tag.text}
                  </Tag>
                ))}
            </ArticleTags>
            <PrimaryHeading renderAs={"h1"}>{postTitle}</PrimaryHeading>
            <ForumInfo>
              <PostDate dateTime={postOriginalPublishDate}>
                {postOriginalPublishDateFormatted}
              </PostDate>
              <CommunityLink
                to={
                  !postDiscourseId
                    ? process.env.GATSBY_COMMUNITY_URL
                    : `${process.env.GATSBY_COMMUNITY_URL}/t/${
                        postDiscourseId || 0
                      }`
                }
              >
                <img src={commentIcon} alt={"Comment"} />
                <CommunityCommentsCount count={count} />
              </CommunityLink>
            </ForumInfo>
          </HeaderArticle>
        </StandardSection>
      </PostHeader>
      <PostContent>
        <SocialLinks>
          <SocialMediaShare slug={postSlug} title={postTitle} />
        </SocialLinks>
        <PostArticle>
          {blogPostContent}
          {Boolean(footnotes.length) && (
            <Footnotes footnotes={footnotes} lang={lang} />
          )}
        </PostArticle>
      </PostContent>
      <PostComments>
        <BlogCommments
          postId={postDiscourseId || 0}
          comments={comments}
          count={count}
          title={postTitle}
          contentfulId={contentful_id}
          category={postDiscourseCategory}
        />
      </PostComments>
      <StoriesWrapper>
        <SecondaryHeading renderAs={"h2"}>Related stories</SecondaryHeading>
        <BlogResults posts={posts.nodes} />
      </StoriesWrapper>
      <Newsletter
        defaultContent={defaultNewsletterContent}
        channel="Mudita website - Blog post"
      />
    </PostWrapper>
  )
}

export default BlogPostArticle

export const query = graphql`
  query RelatedStoriesQuery {
    posts: allContentfulBlogPost(
      limit: 3
      sort: { fields: postOriginalPublishDate, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        postSlug
        postTitle
        postOriginalPublishDate
        postOriginalPublishDateFormatted: postOriginalPublishDate(
          formatString: "MMM D, YYYY"
        )
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
        postFeaturedImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 400
          )
          title
          description
        }
        postDiscourseId
      }
    }
  }
`
